@import '../../../../scss/theme-bootstrap';

// @NOTE: Default styling is for email sign up located in site footer.
// This will probably not be shown in header gnav. Styles for gnav are there just in case.
// @todo styleguide updates - commented out styles are so styleguide can prevail
// @todo remove commented code

.site-email-signup {
  margin: 0 0 17px 0;
  .site-header & {
    max-width: 485px;
    margin: 0 auto;
  }
  &__title {
    @include font--nav;
    margin: 0 0 4px 0;
    @include breakpoint($landscape-up) {
      display: none;
    }
    .site-header & {
      @include h2;
      line-height: 1;
      margin: 0 0 10px 0;
      padding: 0;
      @include breakpoint($landscape-up) {
        display: block;
      }
    }
  }
  &__message {
    &--error {
      color: $color-error;
    }
    &--success {
      color: $color-success;
    }
  }
  &__fields {
    //    padding: 0 15px;
    margin-bottom: 20px;
    .site-header & {
      padding: 0;
    }
  }
  &__field {
    display: block;
    width: 100%;
    margin: 0 auto 10px;
  }
  &__terms-conditions {
    padding: 10px 15px 0 15px;
    display: none;
    position: relative;
    .site-header & {
      padding: 0;
    }
  }
  &__checkboxes {
    margin-bottom: 10px;
    @include breakpoint($landscape-up) {
      margin-bottom: 15px;
    }
  }
  &__checkbox {
    display: block;
    margin: 0 0 7px 0;
    text-transform: none;
    @include breakpoint($landscape-up) {
      margin-bottom: 12px;
    }
  }
  &__checkbox__text {
    p {
      display: inherit;
    }
  }
  &__footer {
    .site-header & {
      @include pie-clearfix;
    }
  }
  &__submit,
  input[type='submit'] {
    width: 100%;
    display: block;
    border-radius: 10px;
    .site-header & {
      display: inline-block;
      float: $rdirection;
      width: auto;
    }
  }
  &__success {
    position: relative;
    text-align: center;
    padding: 2em 0;
    &-header {
      text-transform: uppercase;
    }
    &-offer {
      line-height: 1.5em;
      &__code {
        text-transform: uppercase;
      }
    }
    &-terms {
      margin-top: 1em;
      margin-bottom: 2em;
    }
    &-cta {
      margin-bottom: 2em;
      .button--medium {
        min-width: 172px;
      }
    }
  }
  input {
    border-radius: 10px;
  }
}
